<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="30%"
    @close="closeCallback(false)">
    <div class="i-title" style="margin-top: -30px;margin-bottom: 10px">
      <h3><i class="el-icon-s-order mr-1"></i>添加企业</h3>
    </div>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="155px">
      <el-row>
<!--        <el-col>-->
<!--          <el-form-item label="认证资质：" prop="picturePath">-->
<!--            <template class="avatar-uploader">-->
<!--              <el-upload-->
<!--                :action="$cons.API_SERVER_PATH + $api.UPLOAD.ENTERPRISE"-->
<!--                :headers="uploadHeader"-->
<!--                :data="{userId: this.userId, type: 'headImage'}"-->
<!--                :before-upload="beforeFileUpload"-->
<!--                :on-success="uploadFileSuc"-->
<!--                :show-file-list="false"-->
<!--                class="el-uploader">-->
<!--                <div class="avatar">-->
<!--                  <img :src="licenseUrl ? licenseUrl : uploadUrl" alt="">-->
<!--                </div>-->
<!--                <div class="tip" v-if="licenseUrl">点击重新上传</div>-->
<!--                <div class="tip" v-else>上传营业执照</div>-->
<!--              </el-upload>-->
<!--            </template>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-form-item label="企业名称：" prop="enterpriseName">
        <el-input v-model="dataForm.enterpriseName"  placeholder="请输入企业名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="法人：" prop="corporation">
        <el-input v-model="dataForm.corporation"  placeholder="请输入法人姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码：" prop="socialCode">
        <el-input v-model="dataForm.socialCode"  placeholder="请输入统一社会信用代码" clearable></el-input>
      </el-form-item>
      <el-form-item label="营业范围：" prop="businessScope">
        <el-input v-model="dataForm.businessScope"  placeholder="请输入营业范围" clearable   type="textarea"
                  :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeCallback(false)">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="loading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data () {
      return {
        visible: false,
        dataForm: {
          enterpriseName: '',
          corporation: '',
          socialCode: '',
          picturePath: '',
          businessScope: ''
        },
        // 上传请求头部
        uploadHeader: {
          token: this.$cookie.get('token')
        },
        dataRule: {
          enterpriseName: [
            { required: true, message: '企业名称不能为空', trigger: 'blur' }
          ],
          corporation: [
            { required: true, message: '法人不能为空', trigger: 'blur' }
          ],
          socialCode: [
            { required: true, message: '社会统一信用代码不能为空', trigger: 'blur' }
          ],
          businessScope: [
            { required: true, message: '营业范围不能为空', trigger: 'blur' }
          ]
        },
        dataListLoading: false,
        licenseUrl: '',
        uploadUrl: require('@/assets/img/license.png'),
        loading: false
      }
    },
    computed: {
      userId: {
        get () { return this.$store.state.user.id }
      }
    },
    created () {
    },
    methods: {
      // 初始化
      init () {
        this.visible = true
      },
      closeCallback (refresh) {
        this.visible = false
        this.$emit('closeCallback', refresh)
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            // if (checkIsNull(this.dataForm.picturePath)) {
            //   return this.$message.error('营业执照未上传')
            // }
            this.$confirm('确定所填信息无误?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.loading = true
              this.$http({
                url: this.$http.adornUrl(this.$api.U.ENTERPRISE.SAVE),
                method: 'post',
                data: this.$http.adornData(this.dataForm)
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.loading = false
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: this.closeCallback(true)
                  })
                } else {
                  this.$message.error(data.msg)
                  this.loading = false
                }
              })
            })
          }
        })
      },
      // 附件上传前判断
      beforeFileUpload (file) {
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isLt5M) {
          this.$message.error('上传附件大小不能超过 5MB!')
        }
        return isLt5M
      },
      uploadFileSuc (response, file) {
        if (response && response.code === 0) {
          this.dataForm.id = response.id
          this.dataForm.picturePath = response.path
          this.licenseUrl = URL.createObjectURL(file.raw)
          this.$forceUpdate()
        } else {
          this.$message.error('附件上传失败')
        }
      }
    }
  }
</script>

<style scoped>
  .el-uploader {
    width: 180px;
    height: 180px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: rgb(217, 236, 255);
  }
  .el-uploader .tip {
    background-color: #333;
    color: #fff;
    width: 180px;
    height: 35px;
    line-height: 32px;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    opacity: .6;
  }
  .avatar-uploader .el-uploader:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 16px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    line-height: 250px;
    text-align: center;
  }

  .avatar {
    width: 180px;
    height: 180px;
    padding: 10px 15px 15px;
  }
  .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

</style>
