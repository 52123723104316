<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="40%"
    @close="closeCallback(false)">
<!--    <div class="i-title" style="margin-top: -30px;margin-bottom: 10px">-->
<!--      <el-tag type="success" effect="dark" style="border-radius: 14px 16px 16px 0;width: 80px;height: 30px;line-height: 28px;font-size: 15px">-->
<!--        已认证-->
<!--      </el-tag>-->
<!--    </div>-->
    <div style="margin-bottom: 15px">
      <span style="font-size: 20px;font-weight: bold">
         {{dataForm.enterpriseName}}
        <el-tag v-if="dataForm.enterpriseStatus === '1'" type="success" effect="dark" size="mini">已认证</el-tag>
        <el-tag v-else type="danger" effect="dark" size="mini">未认证</el-tag>
      </span>
    </div>
    <div>
      <el-tag v-if="dataForm.enterpriseState" style="margin-right: 10px;border-radius: 0px 0px 0px 0;">{{dataForm.enterpriseState}}</el-tag>
      <el-tag v-if="dataForm.industry" style="margin-right: 10px;border-radius: 0px 0px 0px 0;">{{dataForm.industry}}</el-tag>
      <el-tag v-if="dataForm.enterpriseType" style="margin-right: 10px;border-radius: 0px 0px 0px 0;">{{dataForm.enterpriseType}}</el-tag>
    </div>
    <div class="company-detail">
      <table cellpadding="0" cellspacing="0">
        <tr>
          <td>
            <div class="company-label">法定代表人</div>
          </td>
          <td colspan="3">{{dataForm.corporation}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">成立时间</div>
          </td>
          <td>{{dataForm.registerTime}}</td>
          <td>
            <div class="company-label">经营状态</div>
          </td>
          <td>{{dataForm.enterpriseState}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">注册资本</div>
          </td>
          <td>
            <span>{{dataForm.registeredCapital}}</span>
          </td>
          <td>
            <div class="company-label">实缴资本</div>
          </td>
          <td>
            <span v-if="dataForm.paidCapital">{{dataForm.paidCapital}}</span>
            <span v-else>--</span>
          </td>
        </tr>
        <tr>
          <td>
            <div class="company-label">统一社会信用代码</div>
          </td>
          <td>{{dataForm.socialCode}}</td>
          <td>
            <div class="company-label">纳税人识别号</div>
          </td>
          <td v-if="dataForm.taxpayerNo">{{dataForm.taxpayerNo}}</td>
          <td v-else>--</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">工商注册号</div>
          </td>
          <td v-if="dataForm.businessCode">{{dataForm.businessCode}}</td>
          <td v-else>--</td>
          <td>
            <div class="company-label">组织机构代码</div>
          </td>
          <td>{{dataForm.orgNo}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">企业类型</div>
          </td>
          <td>{{dataForm.enterpriseType}}</td>
          <td>
            <div class="company-label">所属行业</div>
          </td>
          <td>{{dataForm.industry}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">营业期限</div>
          </td>
          <td>
            {{dataForm.businessTerm}}
          </td>
          <td>
            <div class="company-label">核准日期</div>
          </td>
          <td v-if="dataForm.approvalDate">{{dataForm.approvalDate}}</td>
          <td v-else>--</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">英文名称</div>
          </td>
          <td v-if="dataForm.enterpriseNameEn">{{dataForm.enterpriseNameEn}}</td>
          <td v-else>--</td>
          <td>
            <div class="company-label">曾用名</div>
          </td>
          <td>
            <div style="position: relative; width: 198px;">
              <div class="original-name" v-if="dataForm.enterpriseNameUsed">{{dataForm.enterpriseNameUsed}}</div>
              <div v-else>--</div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="company-label">电话号码</div>
          </td>
          <td>{{dataForm.tel}}</td>
          <td>
            <div class="company-label">邮箱地址</div>
          </td>
          <td>{{dataForm.mail}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">登记机关</div>
          </td>
          <td colspan="3">{{dataForm.registrationAuthority}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">注册地址</div>
          </td>
          <td colspan="3">{{dataForm.enterpriseAddress}}</td>
        </tr>
        <tr>
          <td>
            <div class="company-label">经营范围</div>
          </td>
          <td colspan="3">{{dataForm.businessScope}}</td>
        </tr>
      </table>
    </div>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data () {
      return {
        visible: false,
        dataForm: {},
        dataListLoading: false,
        licenseUrl: '',
        uploadUrl: require('@/assets/img/license.png')
      }
    },
    created () {
    },
    methods: {
      // 初始化
      init (info) {
        this.dataForm = this.$deepClone(info)
        this.visible = true
      },
      closeCallback (refresh) {
        this.visible = false
        this.$emit('closeCallback', refresh)
      },
      // 获取详情
      getEnterpriseInfo (id) {
        this.$http({
          url: this.$http.adornUrl(this.$api.U.ENTERPRISE.INFO),
          method: 'get',
          params: this.$http.adornParams({
            'id': id
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm = data.info
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .el-uploader {
    width: 180px;
    height: 180px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: rgb(217, 236, 255);
  }
  .el-uploader .tip {
    background-color: #333;
    color: #fff;
    width: 180px;
    height: 35px;
    line-height: 32px;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    opacity: .6;
  }
  .avatar-uploader .el-uploader:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 16px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    line-height: 250px;
    text-align: center;
  }

  .avatar {
    width: 180px;
    height: 180px;
    padding: 10px 15px 15px;
  }
  .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .company-detail {
    border-bottom: 1px solid #e4eef6;
    border-left: 1px solid #e4eef6;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgb(228, 238, 246);
    margin-top: 20px;
  }
  .company-label {

  }
  .company-detail table {
    width: 100%;
  }
  .company-detail table tr {
    display: table-row;
    vertical-align: middle;
    border-color: inherit;
  }
  .company-detail td {
    border: 1px solid #e4eef6;
    border-bottom: 0;
    border-left: none;
    width: 250px;
    padding: 11px 16px;
    line-height: 20px;
  }
  .company-detail table tr td:nth-child(odd) {
    width: 145px;
    background-color: #f5fbff;
  }
  .company-detail td {
    border: 1px solid #e4eef6;
    border-bottom: 0;
    border-left: none;
    padding: 11px 16px;
    line-height: 20px;
  }
</style>
